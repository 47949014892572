 
  angular.module('angus.controllers').component('plannedShipment', {
    templateUrl: 'templates/tankMonitor/inventoryManagement/components/plannedShipment/plannedShipmentView.html',  
    bindings: {
        model: '='
    },
    controller: function RiderOrderController($scope, _, plannedShipmentService, inventoryManagementService, toastService) {
        var vm = this; 
  
        this.areCarriersLoading = false; 
        this.isLoading = false;
        this.isNewPlannedShipment = false;
        this.simCardCarriers = [];
        this.riderPlannedShipmentModel = {};  
        this.defaultRiderPlannedShipmentModel = {};  
        this.rowStatus = {
            active: 1,
            delete: 3
        }  

        this.init = function() {   
            vm.isLoading = true;
            Promise.all([
                this.getSimCardCarriers()
            ])
            .then(function(results) { 
                var plannedShipment = vm.model.plannedShipment;
                newPlannedShipmentModel(plannedShipment); 
                if (!vm.model.isNewPlannedShipment) {
                    var plannedShipmentDetails = [];
                    plannedShipment.plannedShipmentDetails.forEach(detail => {
                        plannedShipmentDetails.push(newRiderPlannedShipmentDetailModel(detail));
                    });
                    vm.riderPlannedShipmentModel.plannedShipmentDetails = plannedShipmentDetails;
                } 
            
                vm.defaultRiderPlannedShipmentModel = _.cloneDeep(vm.riderPlannedShipmentModel); 
                vm.isLoading = false; 
            });

            $scope.$watch(function() {
                return vm.riderPlannedShipmentModel;
            }, function(newVal, oldVal) { 
                vm.model.plannedShipmentVMUpdated(false);
                if (!vm.isEqualObject(newVal, vm.defaultRiderPlannedShipmentModel) && oldVal.riderKey) { 
                    vm.model.plannedShipmentVMUpdated(true);
                }
            }, true);
        }; 
 
        this.isEqualObject = function(obj1, obj2) {
            if (obj1 === obj2) return true;
        
            if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
                return false;
            }
        
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
        
            if (keys1.length !== keys2.length) return false;
        
            return keys1.every(key => this.isEqualObject(obj1[key], obj2[key]));
        }

        this.calculateQuantity = function(formPlannedShipment, riderDetailKey, riderPlannedShipmentDetailKey, riderPlannedShipmentDetailQuantity, index) {   
            if (!riderPlannedShipmentDetailQuantity && riderPlannedShipmentDetailQuantity != 0) return;
            
            var selectedRiderDetail = _.find(vm.model.rider.riderDetail, function (riderDetail) {
                return riderDetail.riderDetailKey == riderDetailKey;
            });   
            var calulatedTotal = vm.model.calculateTotalQuantity(riderDetailKey, riderPlannedShipmentDetailKey, riderPlannedShipmentDetailQuantity);
            var valid = (selectedRiderDetail.riderUnitPhysicalQuantity - calulatedTotal) >= 0; 
            if (!valid) { 
                formPlannedShipment.$setPristine();
                toastService.addToast(toastService.types.error, 'Your order value exceeded available quantity!', 5000); 
                vm.riderPlannedShipmentModel.plannedShipmentDetails[index].hasError = true;
                return;
            } 
            else {
                vm.riderPlannedShipmentModel.plannedShipmentDetails[index].hasError = false;
            }

            //check all rows for errors
            var hasError = _.filter(vm.riderPlannedShipmentModel.plannedShipmentDetails, function(ol) {
                return ol.hasError == true;
            }) 
            if (hasError && hasError.length) { 
                formPlannedShipment.$setPristine();
                toastService.addToast(toastService.types.error, 'Your order value exceeded available quantity!', 5000); 
            } 
        }

        this.getSimCardCarriers = function() {   
            vm.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) { 
                vm.simCardCarriers = result.data;  
                vm.areCarriersLoading = false; 
            }); 
        }  

        this.clickDeletePlannedShipment = function(form) {  
            if (vm.riderPlannedShipmentModel.riderPlannedShipmentKey) {
                if(confirm("Are you sure to delete the planned shipment?")) {
                    vm.submitHandler(form, vm.rowStatus.delete); 
                }
            }
            else {
                vm.riderPlannedShipmentModel = null;
                vm.model.deleteVM(vm.model.index);
                toastService.addToast(toastService.types.success, 'The shipment has been saved successfully', 5000); 
            }  
        }

        this.cancelHandler = function() {
            vm.riderPlannedShipmentModel = _.cloneDeep(this.defaultRiderPlannedShipmentModel);
        }

        this.submitHandler = function(form, rowStatusKey) { 
            vm.orderSubmitLoading = true;   
            vm.riderPlannedShipmentModel.updateUser = vm.model.username;  
            vm.riderPlannedShipmentModel.rowStatusKey = rowStatusKey;   

            plannedShipmentService.postRiderPlannedShipment(vm.riderPlannedShipmentModel)
            .then(function(result) {
                var riderPlannedShipmentKey = result.riderPlannedShipmentKey;
                if (riderPlannedShipmentKey > 0) {  
                    if (rowStatusKey == vm.rowStatus.active) {  
                        toastService.addToast(toastService.types.success, 'The shipment has been saved successfully', 5000);  
                    }
                    else {  
                        toastService.addToast(toastService.types.success, 'The shipment has been deleted successfully', 5000); 
                        vm.model.deleteVM(vm.model.index);
                    }
                    vm.model.getRider(vm.model.rider.riderKey);
                    if (form) {
                        form.$setPristine();  
                    }  
                    vm.riderPlannedShipmentModel.riderPlannedShipmentKey = riderPlannedShipmentKey;
                    vm.getRiderPlannedShipments(riderPlannedShipmentKey);
                }
                else { 
                    toastService.addToast(toastService.types.danger, 'Failed to Submit, Please check the data!', 5000); 
                }
            })
            .catch(function(e){
                toastService.addToast(toastService.types.error, 'Failed to Submit, Please check the data!', 5000); 
            }); 
            vm.orderSubmitLoading = false; 
        }

        this.getRiderPlannedShipments = function(riderPlannedShipmentKey) {
            plannedShipmentService.getRiderPlannedShipment(vm.model.rider.riderKey, riderPlannedShipmentKey, vm.model.includeDeleted)
            .then(function(result) {
                vm.riderPlannedShipmentModel.plannedShipmentDetails = vm.mapPlannedShipments(vm.riderPlannedShipmentModel.plannedShipmentDetails, result.plannedShipmentDetails); 
            });
        }

        this.mapPlannedShipments = function(riderDetail, riderDetails) {
            if (riderDetails && riderDetails.length) {
                var newPlannedShipments = _.map(riderDetail, function(rol,index){
                    rol.riderPlannedShipmentDetailKey = riderDetails[index].riderPlannedShipmentDetailKey;
                    rol.riderPlannedShipmentDetailQuantity = riderDetails[index].riderPlannedShipmentDetailQuantity || 0;  
                    rol.riderPlannedShipmentDetailNotes =  riderDetails[index].riderPlannedShipmentDetailNotes;
                    return rol;
                }); 
    
                return newPlannedShipments;
            }
        }

        this.init();

        /* Private *******************************************************************************/
  
        var newPlannedShipmentModel = function(model) {
            vm.riderPlannedShipmentModel = {  
                riderKey                    : model.riderKey ? model.riderKey : 0,
                riderPlannedShipmentKey     : model.riderPlannedShipmentKey ? model.riderPlannedShipmentKey : null,
                riderPlannedShipmentDate    : model.riderPlannedShipmentDate ? new Date(model.riderPlannedShipmentDate) : new Date(),
                riderPlannedShipmentNotes   : model.riderPlannedShipmentNotes ? model.riderPlannedShipmentNotes : '',
                updateUser                  : model.updateUser ? model.updateUser : '',
                rowStatusKey                : model.rowStatusKey ? model.rowStatusKey : 1,
                plannedShipmentDetails      : model.plannedShipmentDetails || []
            }  
        } 

        var newRiderPlannedShipmentDetailModel = function(riderDetail) {   
            var plannedShipment = {
                riderDetailKey                      : riderDetail.riderDetailKey ? riderDetail.riderDetailKey : null,
                riderPlannedShipmentDetailKey       : riderDetail.riderPlannedShipmentDetailKey ? riderDetail.riderPlannedShipmentDetailKey : null,
                riderOrderItem                      : riderDetail.riderOrderItem ? riderDetail.riderOrderItem : null,
                riderOrderCarrier                   : riderDetail.riderOrderCarrier ? riderDetail.riderOrderCarrier : null, 
                riderPlannedShipmentDetailQuantity  : riderDetail.riderPlannedShipmentDetailQuantity ? riderDetail.riderPlannedShipmentDetailQuantity : 0, 
                riderDetailUnitPrice                : riderDetail.riderDetailUnitPrice ? riderDetail.riderDetailUnitPrice : null,
                riderPlannedShipmentDetailNotes     : riderDetail.riderPlannedShipmentDetailNotes ? riderDetail.riderPlannedShipmentDetailNotes : '', 
                riderPlannedShipmentKey             : riderDetail.riderPlannedShipmentKey ? riderDetail.riderPlannedShipmentKey : null,
                rowStatusKey                        : riderDetail.rowStatusKey ? riderDetail.rowStatusKey : 1
            }  
            return plannedShipment; 
        }
    }
});