angular.module('angus.controllers').controller('marginToBudgetController', [
    '$scope', '$rootScope', '$q', '$http', 'hierarchyService', '_', 'moment', 'fiscalYearService', 'restrictedAccessService', 'fluentRest', 'dateCalculator',
    function($scope, $rootScope, $q, $http, hierarchyService, _, moment, fiscalYearService, restrictedAccessService, fluentRest, dateCalculator) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        var divisions = [];
        $scope.budgetDataAvailable = budgetDataAvailable;
        var fiscalYearOfToday = null,
            months;

        function getBudgets() {
            if($scope.widget.instance.settings.hierarchyNodeId.length < 1){
                $scope.budgetTotalsData = {};
                return;
            }
            
            var params = {
                h  : $scope.widget.instance.settings.hierarchyNodeId,
                wdo: $scope.widget.instance.settings.workingDaySubtraction ? $scope.widget.instance.settings.workingDaySubtraction.value : 0,
                pc : $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : null,
                tc: $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : null,
                ppc: $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : null,
                fy : $scope.fiscalYear
            };
            if($scope.widget.instance.settings.periodIndicatorKey && $scope.widget.instance.settings.periodIndicatorKey.value != 'currentYear'){
                params.m  = $scope.month;
            }
            return $scope.widget.promises.monitor($http({
                method: 'GET',
                url: (('api/subscribers/{0}/budgets/variance').format(subscriberId)),
                params
            })
            .then(function(response) {
                $scope.budgetTotalsData = {};
                var budgetsContainer = [];

                _.forEach($scope.categoryNameContainer, function(category) {
                    _.forEach(response.data.budgets, function(budget) {
                        if(category === budget.productCategory) {
                            budgetsContainer.push({
                                productCategory: category,
                                budgetedGrossMargin: budget.budgeted.grossMargin,
                                periodBudgetGrossMargin: budget.periodBudget.grossMargin,
                                currentGrossMargin: budget.current.grossMargin,
                                varianceGrossMargin: budget.variance.grossMargin,
                                percentVarianceGrossMargin: budget.budgeted.grossMargin ? ( (budget.variance.grossMargin / budget.budgeted.grossMargin) * 100) : 0,
                                percentOfBudgetGrossMargin: budget.budgeted.units ? ( (budget.current.grossMargin / budget.budgeted.grossMargin) * 100) : 0,
                                percentOfPeriodBudgetGrossMargin: budget.budgeted.units ? ( (budget.current.grossMargin / budget.periodBudget.grossMargin) * 100) : 0
                            });
                        }
                    });
                });

                $scope.budgetTotalsData.budgets = _.reduce(budgetsContainer, function(result, budget) {
                    var existing = _.find(result, function(item) { return item.productCategory === budget.productCategory });

                    if(existing) {
                        existing.budgetedGrossMargin += budget.budgetedGrossMargin;
                        existing.periodBudgetGrossMargin += budget.periodBudgetGrossMargin;
                        existing.currentGrossMargin += budget.currentGrossMargin;
                        existing.varianceGrossMargin += budget.varianceGrossMargin;
                        existing.percentVarianceGrossMargin = existing.budgetedGrossMargin ? ( (existing.varianceGrossMargin / existing.budgetedGrossMargin) * 100) : 0;
                        existing.percentOfBudgetGrossMargin = existing.budgetedGrossMargin ? ( (existing.currentGrossMargin / existing.budgetedGrossMargin) * 100) : 0;
                        existing.percentOfPeriodBudgetGrossMargin = existing.budgetedGrossMargin ? ( (existing.currentGrossMargin / existing.periodBudgetGrossMargin) * 100) : 0;
                    } else
                        result.push(budget);

                    return result;
                }, []);

                $scope.budgetTotalsData.dataThroughDate = response.data.dataThroughDate;
                $scope.budgetTotalsData.endDate = response.data.endDate;
                $scope.budgetTotalsData.lastTrxDate = response.data.lastTrxDate;
                $scope.budgetTotalsData.startDate = response.data.startDate;
                $scope.budgetTotalsData.workingDays = {
                    elapsed: response.data.workingDays.elapsed,
                    total: response.data.workingDays.total
                };
                $scope.widget.setDefaultActionModel({
                    month: $scope.month,
                    year: $scope.fiscalYear
                })
            }).catch(function(error){
                    $scope.budgetTotalsData = {};
                })
        );
        }
        function budgetDataAvailable(budget){
            return Object.keys(budget ? budget : {} ).length > 0;
        }
        function addYearsToMonths() {
            var year = $scope.fiscalYear;

            if(moment().month(months[0]).month() > 0) year--;

            $scope.months = _.map(months, function(month, index) {
                var monthNumber = moment().month(month).month();

                if(monthNumber === 0 && index !== 0) year++;

                return {
                    display: month,
                    value: monthNumber
                };
            });
        }

        $scope.yearChanged = function() {
            $scope.widget.noContentFlag = false;

            fiscalYearService
                .getFiscalYearMonthsOrdered()
                .then(function(months) {
                    var startMonth = moment().month(months[0]).month();
                    var currentMonth = moment().month();

                    $scope.months = _(months)
                        .map(function(m) {
                            return {
                                value: moment().month(m).month(),
                                display: m
                            };
                        })
                        .filter(function(m) {
                            return (fiscalYearOfToday > $scope.fiscalYear) || (m.value <= currentMonth) || (m.value >= startMonth);
                        })
                        .value();

                    var lastMonth = $scope.months[$scope.months.length - 1].value;

                    if( !$scope.month || ((fiscalYearOfToday === $scope.fiscalYear) && ($scope.month > lastMonth) && ($scope.month < startMonth)) )
                        $scope.month = currentMonth;

                    $scope.reloadWidget();
                });

        };

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            var promise = $q
                .all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered()
                        .then(function(m) {
                            $scope.month = moment().month();
                            $scope.fiscalYear = fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());

                            months = m;
                            addYearsToMonths();
                            $scope.yearChanged();
                        }),
                    restrictedAccessService
                        .getAccess()
                        .then(function(access) {
                            $scope.restrictedAccess = access.restrictedAccess;
                        })
                ])
                .then(function() {
                    return getBudgets();
                })
                .then(function() {
                    return fluentRest
                        .api()
                        .subscribers(subscriberId)
                        .budgets()
                        .yearsForMargin()
                        .get()
                        .then(function(years) {
                            $scope.years = years;
                        });
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.reloadWidget = function() { $scope.widget.promises.monitor(getBudgets()) };
        $scope.fiscalYearChanged = function() { $scope.reloadWidget() };
        $scope.monthChanged = function() { $scope.reloadWidget() };

        $scope.saveDashboardHierarchyNodeDropdownSettings = function(save) {
            if(save) {
                $scope.reloadWidget();
                $scope.widget.saveSettings();
            } else {
                $scope.reloadWidget();
            }
        };

        function checkIfDefaultDashboard() {
            return $http({
                url: 'api/dashboards',
                method: 'GET'
            })
                .then(function(dashboards) {
                    _.forEach(dashboards.data, function(dashboard) {
                        if(dashboard.isDefaultDashboard) {
                            $scope.isDefaultDashboard = true;
                        }
                    })
                });
        }
        checkIfDefaultDashboard();

        $scope.widget.setDefaultInitialization(loadWidget);
        $scope.$on('widgetSettingsChanged', function() {
            $scope.reloadWidget();
        });
    }
]);
